import React from 'react';

import PortfolioItemLayout from '../../components/portfolio/PortfolioItemLayout';

import cover from '../../assets/micro/cover.png';

import r1 from '../../assets/micro/research/r1.png';
import r2 from '../../assets/micro/research/r2.png';
import r3 from '../../assets/micro/research/r3.png';
import r4 from '../../assets/micro/research/r4.png';

import s1 from '../../assets/micro/strategy/s1.png';
import s2 from '../../assets/micro/strategy/s2.png';
import s3 from '../../assets/micro/strategy/s3.png';
import s4 from '../../assets/micro/strategy/s4.png';
import s5 from '../../assets/micro/strategy/s5.png';
import s6 from '../../assets/micro/strategy/s6.png';
import s7 from '../../assets/micro/strategy/s7.png';
import s8 from '../../assets/micro/strategy/s8.png';

import m1 from '../../assets/micro/model/m1.png';
import m2 from '../../assets/micro/model/m2.png';
import m3 from '../../assets/micro/model/m3.png';
import m4 from '../../assets/micro/model/m4.png';
import m5 from '../../assets/micro/model/m5.png';
import m6 from '../../assets/micro/model/m6.png';
import m7 from '../../assets/micro/model/m7.png';

import p1 from '../../assets/micro/prototype/p1.png';
import p2 from '../../assets/micro/prototype/p2.png';
import p3 from '../../assets/micro/prototype/p3.png';
import p4 from '../../assets/micro/prototype/p4.png';

import t1 from '../../assets/micro/testing/t1.png';
import t2 from '../../assets/micro/testing/t2.png';
import t3 from '../../assets/micro/testing/t3.png';
import t4 from '../../assets/micro/testing/t4.png';

const Micro = () => (
    <PortfolioItemLayout
        mainImgSrc={cover}
        title="Micro"
        subtitle="To make home office more like office"
        imgPosition="50%"
        // listing
        sector="Real assets"
        myRole="UX and UI designer in a team of five"
        technology="Axure / Miro / Mural / Airtable"
        projectTime="10.2020 - 07.2021"
        // First section
        firstSectionTitle="Helping people to work in better conditions"
        firstSectionDescription="The product is a complex mobile app that will facilitate short-term rental of a place for remote work. The app will enable searching and booking places adapted to remote work in a close location."
        // rest
        contentSections={[
            {
                title: 'Research',
                description: 'At the beginning of our work on the product, we decided that it would reach two groups. People who work remotely and owners of apartments for rent. Based on the screener we built, we gathered the right number of people to conduct the study. They were gathered using the snowball method. The group of respondents consisted of 15 people (10 working and 5 owners) from cities with population over 100 000 citizens. On the basis of the created research scenario we conducted in-depth interviews in a remote form with the help of Google Meet.',
                images: [
                    { original: r1 },
                    { original: r2 },
                    { original: r3 },
                    { original: r4 },
                ]
            },
            {
                title: 'Strategy',
                description: `Once the interviews were completed, we created 5 personas based on the notes and information collected (which were narrowed down over time to 1 Guest persona and 1 Host persona. ) Through the user research, we drew many conclusions that largely confirmed our hypotheses. \n\nPeople who work remotely cannot find themselves in the new reality, they suffer from lack of social contacts, they don't have enough space in their apartments, their work-life balance is disturbed. People who, before the pandemic, were involved in short-term apartment rentals have lost a steady source - of income. On the other hand, people who have never rented their apartment are apprehensive about potential tenants and are not convinced about long-term renting - they prefer to focus on short-term renting.\n\nTools such as Value Proposition and Customer Jurney Map helped us to go through the thinking process of potential users and define their fears and concerns so we could develop the best solutions for them. Taking into account the fact that people more willingly and often use mobile applications, we decided to create a solution for mobile devices with the Android operating system. We were inspired by generally known products existing on the market, taking into account such aspects as: friendly interface, social elements, search, exchange, sale of products or services.`,
                images: [
                    { original: s1 },
                    { original: s2 },
                    { original: s3 },
                    { original: s4 },
                    { original: s5 },
                    { original: s6 },
                    { original: s7 },
                    { original: s8 },
                ]
            },
            {
                title: 'Modeling',
                description: `In the next step, we created user scenarios that helped visualize the path users would go through when using our application. This allowed us to create diagrams and Lo-fi Wireflows in an easy and clear way. This made the subsequent Prototyping process easier. Above all, it helped us visualize the product and present our ideas about it in a visual way.`,
                images: [
                    { original: m1 },
                    { original: m2 },
                    { original: m3 },
                    { original: m4 },
                    { original: m5 },
                    { original: m6 },
                    { original: m7 },
                ]
            },
            {
                title: 'Prototype',
                description: `The prototype of MICRO application was created in Axure RP 9. Thanks to capabilities of this program we created very complex prototype with many interactions.\nYou can check whole prototype under the link:`,
                images: [
                    { original: p1 },
                    { original: p2 },
                    { original: p3 },
                    { original: p4 },
                ],
                href: 'https://j7kgw3.axshare.com/#id=flng66&p=1__1__ekran_powitalny&g=1',
                hrefDescription: 'Prototyp Micro'
            },
            {
                title: 'Testing',
                description: `User testing was conducted remotely as much as our university conditions allowed. We used a research scenario created by us. We recruited users using a screener and the snowball method mentioned above. Testing was done with the help of Google Meet. After testing, observation and taking notes, we were able to catch technical, critical, minor and significant errors. We observed what they resulted from and fixed the ones that had a significant impact on the users' use of the application and their feelings about interacting with our product.`,
                images: [
                    { original: t1 },
                    { original: t2 },
                    { original: t3 },
                    { original: t4 },
                ]
            },
        ]}
        nextLink="/portfolio/aqua"
    />
)

export default Micro;